import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { toastNotification } from '../shared/toastNotification';

const NewCustomerForm = () => {
  const handleRegister = async (data) => {
    console.log(data);
    fetch('http://localhost:3333/customers/new', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.object === 'subscription') {
          toastNotification('success', 'Cadastro realizado com sucesso!');
        } else {
          toastNotification('error', 'Erro ao realizar cadastro!');
        }
        console.log(data.object);
      });
  };

  const {
    formState,
    register,

    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    resetField,
  } = useForm({
    defaultValues: {},
  });

  return (
    <div>
      <h1 className='text-xl'>Faça seu cadastro para acessar o Vita</h1>
      <form onSubmit={handleSubmit(handleRegister)}>
        <div className='flex flex-col p-4 gap-8 mt-6 '>
          <div className='flex flex-col gap-4 flex-wrap '>
            <TextField
              {...register('name', {
                required: true,
              })}
              id='name'
              label='Nome Completo'
              placeholder='Nome Completo'
              type='text'
            />
            <TextField
              {...register('email', {
                required: true,
              })}
              id='email'
              label='E-mail'
              placeholder='E-mail'
              type='email'
            />
            <TextField
              {...register('password', {
                required: true,
              })}
              id='password'
              label='Senha'
              placeholder='Senha'
              type='password'
            />
            <TextField
              {...register('cpfCnpj', {
                required: true,
              })}
              id='cpfCnpj'
              label='CPF/CNPJ'
              placeholder='CPF/CNPJ'
              type='text'
            />
            <TextField
              {...register('mobilePhone', {
                required: true,
              })}
              id='mobilePhone'
              label='Telefone'
              placeholder='Telefone'
              type='number'
            />
            <TextField
              {...register('postalCode', {
                required: true,
              })}
              id='postalCode'
              label='CEP'
              placeholder='CEP'
              type='number'
            />
            <TextField
              {...register('addressNumber', {
                required: true,
              })}
              id='addressNumber'
              label='Número'
              placeholder='Número'
              type='number'
            />
            <TextField
              {...register('complement', {
                required: true,
              })}
              id='complement'
              label='Complemento'
              placeholder='Complemento'
              type='text'
            />
            {/* name: userRecord.displayName,
          cpfCnpj: '12345678909',
          email: userRecord.email,
          mobilePhone: '32991071618',
          postalCode: '36071120',
          addressNumber: '22',
          complement: 'Casa', */}

            {/* customer: customerId,
              billingType: 'PIX',
              cycle: 'MONTHLY',
              value: '149.90',
              nextDueDate: nextDueDate,
              description: 'Plano Empresarial',
              externalReference: userRecord.uid, */}

            <FormControl fullWidth>
              <InputLabel id='billingType'>Método de Pagamento</InputLabel>
              <Select
                {...register('billingType', { required: true })}
                labelId='billingType'
                id='billingType-select'
                defaultValue=''
                label='Método de Pagamento'
                // onChange={handleProfileChange}
              >
                <MenuItem value='BOLETO'>Boleto</MenuItem>
                <MenuItem value='CREDIT_CARD'>Cartão</MenuItem>
                <MenuItem value='PIX'>PIX</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Button
            className=''
            variant='contained'
            color='success'
            size='large'
            type='submit'
          >
            Finalizar Perfil
          </Button>
        </div>
      </form>
    </div>
  );
};

export default NewCustomerForm;
