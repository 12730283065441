import React, { useEffect, useState } from 'react';
import '../App.css';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import logo from '../assets/simboloVitaResize.png';
import empresa1 from '../assets/clients/empresa1.png';
import empresa2 from '../assets/clients/empresa2.png';
import empresa3 from '../assets/clients/empresa3.jpg';
import empresa4 from '../assets/clients/empresa4.jpg';
import empresa5 from '../assets/clients/empresa5.jpg';
import empresa6 from '../assets/clients/empresa6.png';
import empresa7 from '../assets/clients/empresa7.jpg';
import empresa8 from '../assets/clients/empresa8.png';
import empresa9 from '../assets/clients/empresa9.jpg';
import empresa10 from '../assets/clients/empresa10.jpg';
import empresa11 from '../assets/clients/empresa11.jpg';
import empresa12 from '../assets/clients/empresa12.png';
import empresa13 from '../assets/clients/empresa13.png';
import vitaBg from '../assets/vita-bg.png';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {
  CalendarMonth,
  CardGiftcard,
  CheckCircleOutline,
  CloudCircleOutlined,
  ComputerOutlined,
  SmartphoneOutlined,
} from '@mui/icons-material';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 7,
  },
  largeDesktop: {
    breakpoint: { max: 3000, min: 1300 },
    items: 5,
  },
  mediumDesktop: {
    breakpoint: { max: 1300, min: 1074 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1074, min: 860 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 860, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const textArray = ['o crescimento', 'a vitalidade', 'a organização'];

const LandingPage = () => {
  const [selectedText, setSelectedText] = useState('o crescimento');

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedText((prevText) => {
        const currentIndex = textArray.indexOf(prevText);
        const nextIndex = (currentIndex + 1) % textArray.length;
        return textArray[nextIndex];
      });
    }, 1500);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div className='w-full'>
      <nav>
        <AppBar position='fixed'>
          <Toolbar className='flex gap-2 bg-white h-16'>
            <div>
              <img src={logo} alt='vita-logo' className='w-12' />
            </div>
            <div className='flex justify-evenly w-full'>
              <Link
                to='benefits'
                smooth={true}
                duration={500}
                offset={-20}
                className='text-black font-semibold border-white border-b-2 hover:border-blue-500 cursor-pointer'
              >
                Benefícios
              </Link>
              <Link
                to='testimonials'
                smooth={true}
                duration={500}
                offset={-20}
                className='text-black font-semibold hover:border-b-2 hover:border-blue-500 cursor-pointer'
              >
                Depoimentos
              </Link>
              <Link
                to='howItWorks'
                smooth={true}
                duration={500}
                offset={-20}
                className='text-black font-semibold hover:border-b-2 hover:border-blue-500 cursor-pointer'
              >
                Como funciona
              </Link>
              <Link
                to='plans'
                smooth={true}
                duration={500}
                offset={30}
                className='text-black font-semibold hover:border-b-2 hover:border-blue-500 cursor-pointer'
              >
                Planos
              </Link>
              <a
                href='https://vita.app.br/blog'
                className='text-black font-semibold hover:border-b-2 hover:border-blue-500 cursor-pointer'
              >
                Blog
              </a>
              <RouterLink
                to='/privacidade'
                className='text-black font-semibold hover:border-b-2 hover:border-blue-500 cursor-pointer'
              >
                Privacidade
              </RouterLink>
              <a
                href='https://novo.vita.app.br'
                target='_blank'
                className='text-black font-semibold hover:border-b-2 hover:border-blue-500 cursor-pointer'
              >
                Login
              </a>
            </div>
          </Toolbar>
        </AppBar>
      </nav>
      <div className='flex flex-col items-center justify-center bg-[url("C:\Users\Gustavo\Desktop\Prana\vita_client\src\assets\vita-bg2.png")] bg-cover'>
        <div className='w-3/5 flex flex-col items-center justify-center pt-32 pb-20 gap-12'>
          <h1
            className='text-5xl text-center font-extrabold'
            style={{ color: '#27E2E7' }}
          >
            A solução definitiva para a sua organização e controle financeiro
          </h1>
          <div className='flex flex-col items-start justify-start w-full'>
            <p className='font-bold text-lg text-white mb-4'>
              Com o Vita, você:
            </p>
            <p className='font-bold text-lg text-white'>
              - Faz organização financeira;
            </p>
            <p className='font-bold text-lg text-white'>
              - tem uma planilha de controle financeiro inteligente;
            </p>
            <p className='font-bold text-lg text-white'>
              - diminui a sua ansiedade;
            </p>
            <p className='font-bold text-lg text-white'>
              - aumenta a clareza para tomar decisões;
            </p>
            <p className='font-bold text-lg text-white'>- ganha tempo;</p>
            <p className='font-bold text-lg text-white'>
              - tem mais segurança;
            </p>
          </div>
          <Button variant='contained'>Quero saber mais</Button>
        </div>
      </div>
      <div
        className='flex items-center justify-center text-center w-full py-12'
        style={{ backgroundColor: '#27E2E7' }}
      >
        <h1 className='text-xl font-medium'>
          <span className='font-extrabold text-xl'>
            Acabe com sua INSEGURANÇA!
          </span>{' '}
          Organização, Crescimento, Sucesso, e Resultado são características que
          o VITA proporciona para você empreendedor!
        </h1>
      </div>
      <div
        className='flex flex-col gap-12 pt-12 pb-20 bg-gray-100'
        id='benefits'
      >
        <h1
          className='text-5xl text-center font-extrabold'
          style={{ color: '#27E2E7' }}
        >
          Benefícios do Vita
        </h1>
        <div className='flex flex-col lg:flex-row justify-center items-center lg:items-baseline gap-4 p-6'>
          <div className='flex flex-col w-4/6 lg:w-1/6 items-center gap-3 text-center p-6'>
            <CloudCircleOutlined fontSize='large' sx={{ color: '#27e2e7' }} />
            <h1 className='font-bold text-2xl'>Nuvem</h1>
            <p className='font-medium'>
              Acesse os seus dados de onde você estiver pela internet de forma
              rápida e prática.
            </p>
          </div>
          <div className='flex flex-col w-4/6 lg:w-1/6 items-center gap-3 text-center p-6'>
            <SmartphoneOutlined fontSize='large' sx={{ color: '#27e2e7' }} />
            <h1 className='font-bold text-2xl'>Mobile</h1>
            <p className='font-medium'>
              Faça lançamentos e tenha as informações do seu negócio na palma da
              sua mão.
            </p>
          </div>
          <div className='flex flex-col w-4/6 lg:w-1/6 items-center gap-3 text-center p-6'>
            <ComputerOutlined fontSize='large' sx={{ color: '#27e2e7' }} />
            <h1 className='font-bold text-2xl'>Desktop</h1>
            <p className='font-medium'>
              Faça análises e planejamento com metodologias que empresas de
              sucesso utilizam para gerir e alcançar resultados.
            </p>
          </div>
          <div className='flex flex-col w-4/6 lg:w-1/6 items-center gap-3 text-center p-6'>
            <CalendarMonth fontSize='large' sx={{ color: '#27e2e7' }} />
            <h1 className='font-bold text-2xl'>Customizado</h1>
            <p className='font-medium'>
              Planejamento financeiro específico para o seu modelo de negócio e
              necessidades.
            </p>
          </div>
        </div>
      </div>
      <div className='gap-12 pt-12 pb-20 bg-gray-100' id='testimonials'>
        <h1
          className='text-5xl text-center font-extrabold'
          style={{ color: '#27E2E7' }}
        >
          Veja o depoimento de alguns dos nossos clientes
        </h1>
      </div>
      <div
        className='flex flex-col items-center justify-center gap-6 p-16'
        style={{ backgroundColor: '#53BCC3' }}
      >
        <h1 className='text-7xl text-white'>+400</h1>
        <h1 className='text-2xl font-bold text-white'>
          Empreendedores impactados
        </h1>
        <div className='flex w-full items-center justify-center p-8'>
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            slidesToSlide={1}
            className='w-5/6'
            swipeable
            draggable
            showDots
            dotListClass='custom-dots'
          >
            <img
              src={empresa1}
              alt='empresa1'
              className='object-cover w-44 h-44'
            />
            <img
              src={empresa2}
              alt='empresa2'
              className='object-cover w-44 h-44'
            />
            <img
              src={empresa3}
              alt='empresa3'
              className='object-cover w-44 h-44'
            />
            <img
              src={empresa4}
              alt='empresa4'
              className='object-cover w-44 h-44'
            />
            <img
              src={empresa5}
              alt='empresa5'
              className='object-cover w-44 h-44'
            />
            <img
              src={empresa6}
              alt='empresa6'
              className='object-cover w-44 h-44'
            />
            <img
              src={empresa7}
              alt='empresa7'
              className='object-cover w-44 h-44'
            />
            <img
              src={empresa8}
              alt='empresa8'
              className='object-cover w-44 h-44'
            />
            <img
              src={empresa9}
              alt='empresa9'
              className='object-cover w-44 h-44'
            />
            <img
              src={empresa10}
              alt='empresa10'
              className='object-cover w-44 h-44'
            />
            <img
              src={empresa11}
              alt='empresa11'
              className='object-cover w-44 h-44'
            />
            <img
              src={empresa12}
              alt='empresa12'
              className='object-cover w-44 h-44'
            />
            <img
              src={empresa13}
              alt='empresa13'
              className='object-cover w-44 h-44'
            />
          </Carousel>
        </div>
      </div>
      <div
        className='flex flex-col items-center justify-center gap-4 pt-12 pb-20 bg-gray-100'
        id='howItWorks'
      >
        <h1 className='text-5xl font-bold' style={{ color: '#27E2E7' }}>
          Processo de Implantação
        </h1>
        <p className='font-bold text-xl'>
          4 passos simples para <span>{selectedText}</span> da sua empresa
        </p>
        <div className='flex flex-col justify-center items-center lg:items-baseline gap-4 p-6 lg:flex-row'>
          <div className='flex flex-col w-4/6  lg:w-1/6 text-center items-center gap-4 p-6 '>
            <div
              className='flex w-24 h-24 rounded-full items-center justify-center'
              style={{ backgroundColor: '#34526A' }}
            >
              <p
                className='text-2xl font-extrabold'
                style={{ color: '#27E2E7' }}
              >
                1
              </p>
            </div>
            <h1 className='text-xl font-bold' style={{ color: '#27E2E7' }}>
              Modelagem financeira
            </h1>
            <p>
              Processo realizado junto com um de nossos consultores de forma
              totalmente personalizada para o modelo da sua empresa.
            </p>
          </div>
          <div className='flex flex-col w-4/6  lg:w-1/6 text-center items-center gap-4 p-6 '>
            <div
              className='flex w-24 h-24 rounded-full items-center justify-center'
              style={{ backgroundColor: '#34526A' }}
            >
              <p
                className='text-2xl font-extrabold'
                style={{ color: '#27E2E7' }}
              >
                2
              </p>
            </div>
            <h1 className='text-xl font-bold' style={{ color: '#27E2E7' }}>
              Registro e apresentação da plataforma
            </h1>
            <p>
              Criação da conta no VITA e análise dos grupos de contas.
              Apresentação da interface e das ferramentas e suas
              funcionalidades.
            </p>
          </div>
          <div className='flex flex-col w-4/6  lg:w-1/6 text-center items-center gap-4 p-6 '>
            <div
              className='flex w-24 h-24 rounded-full items-center justify-center'
              style={{ backgroundColor: '#34526A' }}
            >
              <p
                className='text-2xl font-extrabold'
                style={{ color: '#27E2E7' }}
              >
                3
              </p>
            </div>
            <h1 className='text-xl font-bold' style={{ color: '#27E2E7' }}>
              Onboarding da plataforma
            </h1>
            <p>
              Apresentação das metodologias de controle financeiro que o VITA te
              fornece, através dos lançamentos, de maneira automática.
            </p>
          </div>
          <div className='flex flex-col w-4/6  lg:w-1/6 text-center items-center gap-4 p-6 '>
            <div
              className='flex w-24 h-24 rounded-full items-center justify-center'
              style={{ backgroundColor: '#34526A' }}
            >
              <p
                className='text-2xl font-extrabold'
                style={{ color: '#27E2E7' }}
              >
                4
              </p>
            </div>
            <h1 className='text-xl font-bold' style={{ color: '#27E2E7' }}>
              Leitura das informações
            </h1>
            <p>
              Aprendizado com os nossos consultores para a leitura e
              entendimento dos números reais do seu negócio, com geração de um
              plano de ação e tomadas de decisão que garantam o sucesso da sua
              organização.
            </p>
          </div>
        </div>
        <Button variant='contained'>Quero saber mais</Button>
      </div>
      <div
        className='flex flex-col items-center justify-center gap-12 pt-12 pb-20 bg-gray-100'
        id='plans'
      >
        <h1 className='text-5xl font-bold' style={{ color: '#27E2E7' }}>
          Planos
        </h1>
        <div className='flex flex-col lg:flex-row gap-8'>
          <div className='flex flex-col gap-2 border-black border-2 bg-white shadow-md rounded-md h-fit'>
            <div
              className='flex justify-center'
              style={{ backgroundColor: '#27E2E7' }}
            >
              <h1 className='font-extrabold text-2xl p-3'>Liberal</h1>
            </div>
            <div className='flex justify-center'>
              <h1 className='text-2xl font-bold pt-1'>R$</h1>
              <h1 className='text-7xl font-extrabold'>67</h1>
              <h1 className='text-2xl font-bold pt-1'>00</h1>
            </div>
            <div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Modelagem financeira personalizada</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Plano de contas a pagar e receber</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Fluxo de caixa</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Lançamentos</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>DRE e DFC preenchidas automaticamente</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CardGiftcard />
                <p>Atendimento vitalício</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CardGiftcard />
                <p>Implantação com um de nossos consultores</p>
              </div>
            </div>
            <div className='p-8 flex flex-col gap-8 justify-center items-center'>
              <Button variant='contained' color='success'>
                Contratar
              </Button>
              <p className='text-sm font-bold'>
                Para faturamentos até R$6.000,00
              </p>
            </div>
          </div>
          <div className='flex flex-col gap-2 border-black border-2 bg-white shadow-md rounded-md h-fit'>
            <div
              className='flex justify-center'
              style={{ backgroundColor: '#27E2E7' }}
            >
              <h1 className='font-extrabold text-2xl p-3'>Avançado</h1>
            </div>
            <div className='flex justify-center'>
              <h1 className='text-2xl font-bold pt-1'>R$</h1>
              <h1 className='text-7xl font-extrabold'>97</h1>
              <h1 className='text-2xl font-bold pt-1'>00</h1>
            </div>
            <div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Modelagem financeira personalizada</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Plano de contas a pagar e receber</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Fluxo de caixa</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Lançamentos</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>DRE e DFC preenchidas automaticamente</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Gráficos</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Análise com dashboard interativo</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CardGiftcard />
                <p>Atendimento vitalício</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CardGiftcard />
                <p>Implantação com um de nossos consultores</p>
              </div>
              <div className='p-8 flex flex-col gap-8 justify-center items-center'>
                <Button variant='contained' color='success'>
                  Contratar
                </Button>
                <p className='text-sm font-bold'>
                  Para faturamentos entre R$6.000,00 e R$15.000,00
                </p>
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-2 border-black border-2 bg-white shadow-md rounded-md h-fit'>
            <div
              className='flex justify-center'
              style={{ backgroundColor: '#34526A' }}
            >
              <h1 className='font-extrabold text-2xl p-3 text-white'>
                Empresarial
              </h1>
            </div>
            <div className='flex justify-center'>
              <h1 className='text-2xl font-bold pt-1'>R$</h1>
              <h1 className='text-7xl font-extrabold'>147</h1>
              <h1 className='text-2xl font-bold pt-1'>00</h1>
            </div>
            <div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Modelagem financeira personalizada</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Plano de contas a pagar e receber</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Fluxo de caixa</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Lançamentos</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>DRE e DFC preenchidas automaticamente</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Gráficos e calendário preenchidos automaticamente</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Análise com dashboard interativo</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Ferramenta de conciliação bancária</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CardGiftcard />
                <p>Atendimento vitalício</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CardGiftcard />
                <p>Implantação com um de nossos consultores</p>
              </div>
              <div className='p-8 flex flex-col gap-8 justify-center items-center'>
                <Button variant='contained' color='success'>
                  Contratar
                </Button>
                <p className='text-sm font-bold'>
                  Para faturamentos superiores a R$15.000,00
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
